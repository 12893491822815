import React, {Component} from 'react';
import {Container, Col, Row, InputGroup, FormControl, Accordion, Card, Button, Form} from "react-bootstrap";
import Select from "react-select";
import placeholderSquare from "../images/PlaceholderSquare.png"
import {
    updateMainImageSearchResults,
    updateMainImageSelection, updateTagSelectionMainImages
} from "../actions";
import {
    updateEditWallSearchResults,
    updateEditTagSelectionMainImages,
    updateEditMainImageSelection, updateEditMainImageSearchResults,
} from "../actions/edit"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import ScaleLoader from "react-spinners/ScaleLoader";

class MainImageSearchModalComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sendingSearchRequest: false,
            imagesToLoad: 0,
            imagesLoading: false,
            loadedImagesCount: 0
        }

        this.handleTagSelectionChange = this.handleTagSelectionChange.bind(this);
        this.selectMainImage = this.selectMainImage.bind(this);
        this.searchForMainImages = this.searchForMainImages.bind(this);
        this.filterTags = this.filterTags.bind(this);
        this.onImageLoad = this.onImageLoad.bind(this);
    }

    handleTagSelectionChange(tags) {

        if (this.props.isCreate === true) {

            this.props.dispatch(updateTagSelectionMainImages({
                    searchTags: tags
                }
            ))
        } else {

            this.props.dispatch(updateEditTagSelectionMainImages({
                    searchTags: tags
                }
            ))
        }
    }

    onImageLoad(){

        if(this.state.imagesLoading === true) {

            let currentLoadedImagesCount = this.state.loadedImagesCount
            this.setState({loadedImagesCount: currentLoadedImagesCount + 1})

            if (currentLoadedImagesCount + 1 >= this.state.imagesToLoad * 0.95) {
                this.setState({imagesLoading: false, imagesToLoad: 0, loadedImagesCount: 0})
            }
        }

    }

    selectMainImage(searchResult) {

        if (this.props.isCreate === true) {

            this.props.dispatch(updateMainImageSelection({
                    defaultWall: searchResult
                }
            ))

            this.props.handleMainImageSelectChange(false)
        } else {

            this.props.dispatch(updateEditMainImageSelection({
                    defaultWall: searchResult
                }
            ))
            this.props.handleMainImageSelectChange(false)
        }
    }

    searchForMainImages() {

        let tagIds = this.props.searchTags.map((obj) => {
            return obj["value"]
        }).toString()

        if (this.props.token !== "" &&
            this.props.token !== undefined &&
            this.state.sendingSearchRequest === false) {

            let config = {
                headers: {
                    Authorization: `Bearer ${this.props.token}`,
                    "Access-Control-Allow-Origin": "*"
                }
            }

            this.setState({sendingSearchRequest: true})

            axios.get(`${process.env.REACT_APP_SERVER_URL}defaultwall/searchv2?mainImages=true&tags=${tagIds}`, config).then(result => {

                    let imagesToLoad = result.data.length
                    if (imagesToLoad > 0){
                        this.setState({imagesToLoad: imagesToLoad, imagesLoading: true})
                    }

                    if (this.props.isCreate === true) {
                        this.props.dispatch(updateMainImageSearchResults({searchResults: result.data}))
                    } else {
                        this.props.dispatch(updateEditMainImageSearchResults({searchResults: result.data}))
                    }
                }
            ).catch(err => {
            }).finally(result => {
                this.setState({sendingSearchRequest: false})
                setTimeout( () => {
                    this.setState({imagesLoading: false, imagesToLoad: 0, loadedImagesCount: 0})
                }, 10000);
            })
        }
    }

    filterTags() {
        let filteredTags = this.props.tagOptions.filter((obj) => obj["isBackgroundOnly"] === false)
        return filteredTags.map((obj) => {
            return {"value": obj["id"], "label": obj["name"]}
        })
    }

    render() {

        let imageStyle = {
            width: "100%",
            height: "90%",
            margin: "auto",
            display: "block",
            borderRadius: 10,
            boxShadow: "0px 2px 5px -1px #333"
        }

        let thumbnailStyle = {
            width: "75px",
            height: "75px",
            margin: "auto",
            float: "left",
        }

        let selectMainImageFunction = this.selectMainImage

        let onImageLoadFunction = this.onImageLoad

        let filteredAndMappedTags = this.filterTags()

        return (
            <div>
                <Container fluid>
                    <Row>
                        <Col sm={6}>
                            <br></br>
                            <h1>Search Main Images</h1>
                            <br/>
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col sm={6}>
                            <Select options={filteredAndMappedTags}
                                    isMulti
                                    placeholder="Select Tags"
                                    value={this.props.searchTags}
                                    isOptionDisabled={() => this.props.searchTags.length >= 5}
                                    onChange={(event) => this.handleTagSelectionChange(event)}
                            />
                        </Col>

                        <Col sm={3}>
                            <Button
                                onClick={() => this.searchForMainImages()}
                                style={this.props.buttonStyle}>
                                Search Main Images <FontAwesomeIcon icon={faMagnifyingGlass}/>
                            </Button>
                        </Col>
                    </Row>
                    <br/>

                    {this.props.previouslySelected.length > 0 && <div>
                        <Row>
                            <h6>Previously Selected</h6>
                        </Row>
                        <Row>
                            <span>
                            {
                                this.props.previouslySelected.map(function (defaultWall, i) {
                                    return (<div style={{
                                        "display": "inline-block",
                                        "margin-right": "10px",
                                        position: "relative"
                                    }}>
                                        <a href={"#"} onClick={() => selectMainImageFunction(defaultWall)}>
                                            <img style={thumbnailStyle} src={defaultWall.mainImagePath}/>
                                        </a><br/></div>);
                                })
                            }
                                </span>
                        </Row>
                        <br></br>
                    </div>}
                    <Row>
                        <Col sm={4}>
                            {
                                this.props.searchResults.map(function (searchResult, i) {
                                    if (i % 3 === 0) {
                                        return (<div style={{position: "relative"}}><a href={"#"}
                                                                                       onClick={() => selectMainImageFunction(searchResult)}>
                                            <img style={imageStyle} src={searchResult.mainImagePath} onLoad={() => onImageLoadFunction()}/>
                                        </a><br/></div>);
                                    }
                                })}
                        </Col>
                        <Col sm={4}>
                            {
                                this.props.searchResults.map(function (searchResult, i) {
                                    if (i % 3 === 1) {
                                        return (<div style={{position: "relative"}}><a href={"#"}
                                                                                       onClick={() => selectMainImageFunction(searchResult)}>
                                            <img style={imageStyle} src={searchResult.mainImagePath} onLoad={() => onImageLoadFunction()}/>
                                        </a><br/></div>);
                                    }
                                })}
                        </Col>
                        <Col sm={4}>
                            {
                                this.props.searchResults.map(function (searchResult, i) {
                                    if (i % 3 === 2) {
                                        return (<div style={{position: "relative"}}><a href={"#"}
                                                                                       onClick={() => selectMainImageFunction(searchResult)}>
                                            <img style={imageStyle} src={searchResult.mainImagePath} onLoad={() => onImageLoadFunction()}/>
                                        </a><br/></div>);
                                    }
                                })}
                        </Col>

                        {this.state.imagesLoading && <div style = {{ position: "absolute",
                            left: "0",
                            height: "100%",
                            textAlign: "center",
                            background: "rgba(255, 255, 255, 0.95)"}}>

                            <br></br>
                            <ScaleLoader marginTop={20} color={"#8639F1"} loading={this.state.imagesLoading} size={150}/>

                        </div>}
                    </Row>
                    <br></br>
                </Container>
            </div>)
    }
}

export default MainImageSearchModalComponent;