import React, {Component} from 'react';
import {Container, Col, Row, Breadcrumb} from "react-bootstrap";
import {Link, Redirect, Route, Switch} from "react-router-dom"
import 'react-image-picker/dist/index.css'
import {connect} from 'react-redux';
import {
    updateCurrentBreadcrumb
} from '../actions/edit';
import './CreateWallComponent.css'
import '@brainhubeu/react-carousel/lib/style.css';

import EditWallDesignComponent from "./EditWallDesignComponent";
import EditDeliveryComponent from "./EditDeliveryComponent";
import * as PropTypes from "prop-types";
import EditConfirmationComponent from "./EditConfirmationComponent";
import {withAuth0} from "@auth0/auth0-react";
import ConfirmationComponent from "./ConfirmationComponent";
import EditPaymentComponent from "./EditPaymentComponent";

EditConfirmationComponent.propTypes = {updateCurrentBreadcrumb: PropTypes.func};

class EditWallComponent extends Component {

    constructor(props) {
        super(props);
        this.setCurrentBreadcrumb = this.setCurrentBreadcrumb.bind(this);
    }

    setCurrentBreadcrumb(pageName) {

        this.props.dispatch(updateCurrentBreadcrumb({
                currentBreadcrumb: pageName
            }
        ))

    }

    render() {

        const {isAuthenticated, isLoading} = this.props.auth0;

        if (isLoading) {
            return (
                <div>
                    <h6 style={{textAlign: "center"}}>Loading</h6>
                </div>
            )
        } else if (!isAuthenticated && !isLoading) {
            return (
                <div>
                    <Redirect to={'/home'}/>
                </div>
            )
        } else {

            return (
                <div>
                    <Container>
                        <br></br>
                        <Row>
                            <Col sm={6}>
                                <Breadcrumb>
                                    <Breadcrumb.Item className={"wallBreadcrumb"}
                                                     active={this.props.currentBreadcrumb === "DesignWall"}
                                                     linkAs={Link}
                                                     linkProps={{to: "/editwall/designwall/" + this.props.uuid}}>
                                        Edit Wall
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item className={"wallBreadcrumb"}
                                                     active={this.props.currentBreadcrumb === "ScheduleDelivery"}
                                                     linkAs={Link}
                                                     linkProps={{to: "/editwall/scheduledelivery/" + this.props.uuid}}>
                                        Edit Delivery
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        className={"wallBreadcrumb"}
                                        active={this.props.currentBreadcrumb === "Payment"}
                                    >
                                        Payment
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        className={"wallBreadcrumb"}
                                        active={this.props.currentBreadcrumb === "Confirmation"}
                                    >
                                        Confirmation
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                            <Col sm={3}>
                            </Col>
                            <Col sm={3}>
                            </Col>
                        </Row>
                        <Switch>
                            <Route path='/editwall/designwall/:uuid'>
                                <EditWallDesignComponent
                                    updateCurrentBreadcrumb={this.setCurrentBreadcrumb}
                                    wallTitle={this.props.wallTitle}
                                    selectionStart={this.props.selectionStart}
                                    selectionEnd={this.props.selectionEnd}
                                    isCentred={this.props.isCentred}
                                    isTransparent={this.props.isTransparent}
                                    isConfetti={this.props.isConfetti}
                                    isCustomise={this.props.isCustomise}
                                    confettiColorsAndIds={this.props.confettiColorsAndIds}
                                    font={this.props.font}
                                    fontSize={this.props.fontSize}
                                    fontColour={this.props.fontColour}
                                    deliveryDate={this.props.deliveryDate}
                                    deliveryTime={this.props.deliveryTime}
                                    s3Path={this.props.s3Path}
                                    searchResults={this.props.searchResults}
                                    searchTags={this.props.searchTags}
                                    searchResultsMainImages={this.props.searchResultsMainImages}
                                    searchTagsMainImages={this.props.searchTagsMainImages}
                                    isMainImage={this.props.isMainImage}
                                    mainImagePath={this.props.mainImagePath}
                                    mainImageId={this.props.mainImageId}
                                    personalImagePath={this.props.personalImagePath}
                                    mainImageUploadedPicture={this.props.mainImageUploadedPicture}
                                    previouslySelected={this.props.previouslySelected}
                                    previouslySelectedMainImages={this.props.previouslySelectedMainImages}
                                    s3Id={this.props.s3Id}
                                    tagOptions={this.props.tagOptions}
                                    dispatch={this.props.dispatch}/>
                            </Route>
                            <Route path='/editwall/scheduledelivery/:uuid'>
                                <EditDeliveryComponent dispatch={this.props.dispatch}
                                                       updateCurrentBreadcrumb={this.setCurrentBreadcrumb}
                                                       {...this.props}/>
                            </Route>
                            <Route path='/editwall/payment/:uuid/:freewall'>
                                <EditPaymentComponent dispatch={this.props.dispatch}
                                                       updateCurrentBreadcrumb={this.setCurrentBreadcrumb}
                                                       {...this.props}/>
                            </Route>
                            <Route path='/editwall/confirmation/:uuid/:confirmationtype'>
                                <EditConfirmationComponent updateCurrentBreadcrumb={this.setCurrentBreadcrumb}
                                                           dispatch={this.props.dispatch}/>
                            </Route>
                        </Switch>
                    </Container>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {

    return {
        wallTitle: state.getIn(["centralState", "editInProgress", "wallTitle"]),
        selectionStart: state.getIn(["centralState", "editInProgress", "selectionStart"]),
        selectionEnd: state.getIn(["centralState", "editInProgress", "selectionEnd"]),
        isCentred: state.getIn(["centralState", "editInProgress", "isCentred"]),
        isTransparent: state.getIn(["centralState", "editInProgress", "isTransparent"]),
        font: state.getIn(["centralState", "editInProgress", "font"]),
        fontSize: state.getIn(["centralState", "editInProgress", "fontSize"]),
        fontColour: state.getIn(["centralState", "editInProgress", "fontColour"]),
        s3Id: state.getIn(["centralState", "editInProgress", "s3Id"]),
        s3Path: state.getIn(["centralState", "editInProgress", "s3Path"]),
        mainImageId: state.getIn(["centralState", "editInProgress", "mainImageId"]),
        mainImagePath: state.getIn(["centralState", "editInProgress", "mainImagePath"]),
        personalImagePath: state.getIn(["centralState", "editInProgress", "personalImagePath"]),
        isMainImage: state.getIn(["centralState", "editInProgress", "isMainImage"]),
        currentBreadcrumb: state.getIn(["centralState", "editInProgress", "currentBreadcrumb"]),
        deliveryDate: state.getIn(["centralState", "editInProgress", "deliveryDate"]),
        searchTags: state.getIn(["centralState", "editInProgress", "searchTags"]),
        searchResults: state.getIn(["centralState", "editInProgress", "searchResults"]),
        previouslySelected: state.getIn(["centralState", "editInProgress", "previouslySelected"]),
        searchTagsMainImages: state.getIn(["centralState", "editInProgress", "searchTagsMainImages"]),
        searchResultsMainImages: state.getIn(["centralState", "editInProgress", "searchResultsMainImages"]),
        previouslySelectedMainImages: state.getIn(["centralState", "editInProgress", "previouslySelectedMainImages"]),
        mainImageUploadedPicture: state.getIn(["centralState", "editInProgress", "mainImageUploadedPicture"]),
        isPremium: state.getIn(["centralState", "editInProgress", "isPremium"]),
        deliveryTime: state.getIn(["centralState", "editInProgress", "deliveryTime"]),
        isConfetti: state.getIn(["centralState", "editInProgress", "isConfetti"]),
        isCustomise: state.getIn(["centralState", "editInProgress", "isCustomise"]),
        tagOptions: state.getIn(["centralState", "editInProgress", "tagOptions"]),
        confettiColorsAndIds: state.getIn(["centralState", "editInProgress", "confettiColorsAndIds"]),
        recipientList: state.getIn(["centralState", "editInProgress", "recipientList"]),
        isDraft: state.getIn(["centralState", "editInProgress", "isDraft"]),
        isPaid: state.getIn(["centralState", "editInProgress", "isPaid"]),
        isActive: state.getIn(["centralState", "editInProgress", "isActive"]),
        isSent: state.getIn(["centralState", "editInProgress", "isSent"]),
        uuid: state.getIn(["centralState", "editInProgress", "uuid"])
    }
}

export default withAuth0(connect(mapStateToProps)(EditWallComponent));