import React, {Component} from 'react';
import {Container, Col, Row, InputGroup, FormControl, Accordion, Card, Button, Form} from "react-bootstrap";
import Select from "react-select";
import {
    updateDefaultWallSearchResults, updateDefaultWallSelection,
    updateDefaultWallTagSelection, updateIsMainImage
} from "../actions";
import {
    updateEditTagSelection,
    updateEditWallSelection,
     updateEditWallSearchResults,
    updateIsEditMainImage
} from "../actions/edit"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import ScaleLoader from "react-spinners/ScaleLoader";


class ThemeSearchModalComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sendingSearchRequest: false,
            imagesToLoad: 0,
            imagesLoading: false,
            loadedImagesCount: 0
        }

        this.handleTagSelectionChange = this.handleTagSelectionChange.bind(this);
        this.selectWall = this.selectWall.bind(this);
        this.searchForWalls = this.searchForWalls.bind(this);
        this.filterTags = this.filterTags.bind(this);
        this.onImageLoad = this.onImageLoad.bind(this);
    }

    handleTagSelectionChange(tags) {

        if (this.props.isCreate === true) {

            this.props.dispatch(updateDefaultWallTagSelection({
                    searchTags: tags
                }
            ))
        } else {
            this.props.dispatch(updateEditTagSelection({
                    searchTags: tags
                }
            ))
        }
    }

    onImageLoad(){

        if(this.state.imagesLoading === true) {

            let currentLoadedImagesCount = this.state.loadedImagesCount
            this.setState({loadedImagesCount: currentLoadedImagesCount + 1})

            if (currentLoadedImagesCount + 1 >= this.state.imagesToLoad * 0.95) {
                this.setState({imagesLoading: false, imagesToLoad: 0, loadedImagesCount: 0})
            }
        }

    }

    handleIncludeMainImageChange(event) {

        if (this.props.isCreate === true) {
            this.props.dispatch(updateIsMainImage({
                    isMainImage: event.target.checked
                }
            ))
        } else {
            this.props.dispatch(updateIsEditMainImage({
                    isMainImage: event.target.checked
                }
            ))
        }
    }

    selectWall(searchResult) {

        if (this.props.isCreate === true) {

            this.props.dispatch(updateDefaultWallSelection({
                    defaultWall: searchResult
                }
            ))

            this.props.handleBackgroundSelectChange(false)
        } else {

            this.props.dispatch(updateEditWallSelection({
                    defaultWall: searchResult
                }
            ))
            this.props.handleBackgroundSelectChange(false)
        }
    }

    searchForWalls() {

        let tagIds = this.props.searchTags.map((obj) => {
            return obj["value"]
        }).toString()

        if (this.props.token !== "" &&
            this.props.token !== undefined &&
            this.state.sendingSearchRequest === false) {

            let config = {
                headers: {
                    Authorization: `Bearer ${this.props.token}`,
                    "Access-Control-Allow-Origin": "*"
                }
            }

            this.setState({sendingSearchRequest: true})

            axios.get(`${process.env.REACT_APP_SERVER_URL}defaultwall/searchv2?mainImages=${this.props.isMainImage}&tags=${tagIds}`, config).then(result => {

                    let imagesToLoad = this.props.isMainImage === true? result.data.length*2: result.data.length
                    if (result.data.length > 0){
                        this.setState({imagesToLoad: imagesToLoad, imagesLoading: true})
                    }

                    if (this.props.isCreate === true) {
                        this.props.dispatch(updateDefaultWallSearchResults({searchResults: result.data}))
                    } else {
                        this.props.dispatch(updateEditWallSearchResults({searchResults: result.data}))
                    }
                }
            ).catch(err => {
            }).finally(result => {
                this.setState({sendingSearchRequest: false})
                setTimeout( () => {
                    this.setState({imagesLoading: false, imagesToLoad: 0, loadedImagesCount: 0})
                }, 10000);
            })
        }
    }

    filterTags(){

        let filteredTags

        if (this.props.isMainImage === false){

            //filter tags where is_background_only is either true or null
            filteredTags = this.props.tagOptions.filter((obj) => obj["isBackgroundOnly"] !== false )
        } else {
            //filter tags where is_background_only is false
            filteredTags = this.props.tagOptions.filter((obj) => obj["isBackgroundOnly"] === false )
        }
        return filteredTags.map((obj) => {
            return {"value": obj["id"], "label": obj["name"]}})
    }

    render() {

        let imageStyle = {
            width: "100%",
            height: "90%",
            margin: "auto",
            display: "block",
            borderRadius: 10,
            boxShadow: "0px 2px 5px -1px #333"
        }

        let mainImageStyle = {
            position: "absolute",
            top: "50%",
            left: "50%",
            display: "block",
            width: "70%",
            transform: "translate(-50%, -50%)",
            borderRadius: 10,
            boxShadow: "0px 2px 5px -1px #333"
        }

        let thumbnailStyle = {
            width: "75px",
            height: "75px",
            margin: "auto",
            float: "left",
        }

        let thumbnailMainImageStyle = {
            position: "absolute",
            top: "50%",
            left: "50%",
            display: "block",
            width: "70%",
            transform: "translate(-50%, -50%)",
        }

        let selectWallFunction = this.selectWall

        let onImageLoadFunction = this.onImageLoad

        let filteredAndMappedTags = this.filterTags()

        return (
            <div>
                <Container fluid>
                    <Row>
                        <Col sm={6}>
                            <br></br>
                            <h1>Search Walls</h1>
                            <br/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <Form>
                                <div className="checkboxes">
                                    <Form>
                                        <Form.Check
                                            className={"premiumToggle"}
                                            type="switch"
                                            checked={this.props.isMainImage}
                                            id="transparent-switch"
                                            label="Include main image"
                                            onChange={(e) => this.handleIncludeMainImageChange(e)}
                                        />
                                    </Form>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col sm={6}>
                            <Select options={filteredAndMappedTags}
                                    isMulti
                                    placeholder="Select Tags"
                                    value={this.props.searchTags}
                                    isOptionDisabled={() => this.props.searchTags.length >= 5}
                                    onChange={(event) => this.handleTagSelectionChange(event)}
                            />
                        </Col>

                        <Col sm={3}>
                            <Button
                                onClick={() => this.searchForWalls()}
                                style={this.props.buttonStyle}>
                                Search Walls <FontAwesomeIcon icon={faMagnifyingGlass}/>
                            </Button>
                        </Col>
                    </Row>
                    <br/>

                    {this.props.previouslySelected.length > 0 && <div>
                        <Row>
                            <h6>Previously Selected</h6>
                        </Row>
                        <Row>
                            <span>
                            {
                                this.props.previouslySelected.map(function (defaultWall, i) {
                                        return (<div style={{"display": "inline-block", "margin-right": "10px", position: "relative"}}>
                                            <a href={"#"} onClick={() => selectWallFunction(defaultWall)}>
                                            <img style={thumbnailStyle} src={defaultWall.s3Path}/>
                                            <img style={thumbnailMainImageStyle} src={defaultWall.mainImagePath}/></a><br/></div>);
                                    })
                                }
                                </span>
                        </Row>
                        <br></br>
                    </div>}
                    <Row>
                        {this.props.searchResults.length > 0 &&
                        <div>
                            <h6>Search Results</h6>
                        </div>}
                    </Row>
                    <Row>
                        <Col sm={4}>
                            {
                                this.props.searchResults.map(function (searchResult, i) {
                                    if (i % 3 === 0) {
                                        return (<div style={{position: "relative"}}><a href={"#"} onClick={() => selectWallFunction(searchResult)}>
                                            <img style={imageStyle} src={searchResult.s3Path} onLoad={() => onImageLoadFunction()}/>
                                            <img style={mainImageStyle} src={searchResult.mainImagePath} onLoad={() => onImageLoadFunction()}/></a><br/></div>);
                                    }
                                })}
                        </Col>
                        <Col sm={4}>
                            {
                                this.props.searchResults.map(function (searchResult, i) {
                                    if (i % 3 === 1) {
                                        return (<div style={{position: "relative"}}><a href={"#"} onClick={() => selectWallFunction(searchResult)}>
                                            <img style={imageStyle} src={searchResult.s3Path} onLoad={() => onImageLoadFunction()}/>
                                            <img style={mainImageStyle} src={searchResult.mainImagePath} onLoad={() => onImageLoadFunction()}/></a><br/></div>);
                                    }
                                })}
                        </Col>
                        <Col sm={4}>
                            {
                                this.props.searchResults.map(function (searchResult, i) {
                                    if (i % 3 === 2) {
                                        return (<div style={{position: "relative"}}><a href={"#"} onClick={() => selectWallFunction(searchResult)}>
                                            <img style={imageStyle} src={searchResult.s3Path} onLoad={() => onImageLoadFunction()}/>
                                            <img style={mainImageStyle} src={searchResult.mainImagePath} onLoad={() => onImageLoadFunction()}/></a><br/></div>);
                                    }
                                })}
                        </Col>

                        {this.state.imagesLoading && <div style = {{ position: "absolute",
                            left: "0",
                            height: "100%",
                            textAlign: "center",
                            background: "rgba(255, 255, 255, 0.95)"}}>

                            <br></br>
                            <ScaleLoader marginTop={20} color={"#8639F1"} loading={this.state.imagesLoading} size={150}/>

                        </div>}

                    </Row>

                    <br></br>
                </Container>
            </div>)
    }


}

export default ThemeSearchModalComponent;