import React, {Component} from "react";
import {Col, Row, Form, Input, Button, Modal, FormGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {ReactMultiEmail, isEmail} from "react-multi-email";
import 'react-multi-email/style.css';
import {Redirect, withRouter} from "react-router-dom"
import {
    updateDeliveryDate,
    updateDeliveryTime,
    updateEditingWall,
    updateIsPremium,
    updateRecipientList
} from "../actions/edit";
import axios from 'axios';
import {connect} from "react-redux";
import {withAuth0} from "@auth0/auth0-react";
import ScaleLoader from "react-spinners/ScaleLoader";
import './EditDeliveryComponent.css'
import TimePicker from 'react-bootstrap-time-picker';
import {assignConfettiColorsIds, extractConfettiColorsOnly} from "../utils/utils";


class EditDeliveryComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            errors: {},
            showErrorModal: false,
            uuid: this.props.match.params.uuid,
            redirectToPayment: false,
            redirectToSaveDraftConfirmation: false,
            redirectToEditActiveConfirmation: false,
            validated: false,
            submitPressed: "",
        }

        this.handleDeliveryTimeChange = this.handleDeliveryTimeChange.bind(this);
        this.handleDeliveryDateChange = this.handleDeliveryDateChange.bind(this);
        this.handleRecipientListChange = this.handleRecipientListChange.bind(this);
        this.openModal = this.openModal.bind(this);
        this.setIsPremium = this.setIsPremium.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.submitBoard = this.submitBoard.bind(this);
        this.submitBoardNoValidation = this.submitBoardNoValidation.bind(this);
        this.fetchWall = this.fetchWall.bind(this);
    }

    fetchWall(uuid) {

        let config = {
            headers: {
                Authorization: `Bearer ${this.props.token}`,
                "Access-Control-Allow-Origin": "*"
            }
        }

        axios.get(`${process.env.REACT_APP_SERVER_URL}getWallByUuid/` + uuid,
            config).then(result => {

            let wall = result.data

            let colorsWithIds = assignConfettiColorsIds(wall.confettiColorsList)

            this.props.dispatch(
                updateEditingWall({
                        deliveryDate: wall.deliveryDate,
                        deliveryTime: wall.deliveryTime,
                        font: wall.font,
                        fontColour: wall.fontColour,
                        fontSize: wall.fontSize,
                        isActive: wall.active,
                        isCentred: wall.centred,
                        isDraft: wall.draft,
                        isPaid: wall.paid,
                        isPremium: wall.premium,
                        isSent: wall.sent,
                        s3Id: wall.s3Id,
                        s3Path: wall.s3Path,
                        isConfetti: wall.isConfetti,
                        confettiColorsAndIds: colorsWithIds,
                        isTransparent: wall.transparent,
                        recipientList: wall.recipientList,
                        wallTitle: wall.text,
                        uuid: wall.uuid,
                    }
                ))

        }).catch(err => {
            console.log(`Something went wrong ${err}`);
        })
    }


    handleDeliveryDateChange(event) {

        this.props.dispatch(updateDeliveryDate({
                deliveryDate: event.target.value
            }
        ))
    }

    handleDeliveryTimeChange(event) {

        this.props.dispatch(updateDeliveryTime({
                deliveryTime: event,
            }
        ))
    }

    handleRecipientListChange(event) {
        //Restrict number of recipients to 10
        event = event.slice(0, 10)
        this.props.dispatch(updateRecipientList({
                recipientList: event
            }
        ))
    }

    additionalValidation() {
        let errors = {}

        let limit = new Date(new Date().getTime() + 1 * 60000);

        if (new Date(Date.parse(this.props.deliveryDate) + this.props.deliveryTime * 1000) < limit) {
            errors["Delivery Date"] = "Delivery date and time must be at least 1 minute in the future"
        }
        if (this.props.recipientList.length === 0) {
            errors["Recipient Email List"] = "No recipients specified"
        }
        return errors;
    }

    editWall() {
        let config = {
            headers: {
                Authorization: `Bearer ${this.props.token}`,
                "Access-Control-Allow-Origin": "*",
                "Accept": "application/json",
                "Content-Type": "multipart/form-data"
            }
        }

        this.setState({"loading": true})

        let colors = extractConfettiColorsOnly(this.props.confettiColorsAndIds)

        let form = new FormData()

        //Do we have to change the "is" statements here, like in the deliveryComponent?
        let json = JSON.stringify(
            {
                uuid: this.props.uuid,
                text: this.props.wallTitle,
                centred: this.props.isCentred,
                tile: this.props.s3Id,
                s3Id: this.props.s3Id,
                mainImageId: this.props.mainImageId,
                mainImagePath: this.props.mainImagePath,
                transparent: this.props.isTransparent,
                font: this.props.font,
                fontSize: this.props.fontSize,
                fontColour: this.props.fontColour,
                deliveryDate: this.props.deliveryDate,
                deliveryTime: this.props.deliveryTime,
                recipientList: this.props.recipientList,
                personalImagePath: this.props.personalImagePath,
                active: this.props.isActive,
                draft: this.props.isDraft,
                sent: this.props.isSent,
                paid: this.props.isPaid,
                premium: this.props.isPremium,
                confetti: this.props.isConfetti,
                confettiColorsList: colors
            }
        )

        form.append("walldata", json)

        if (this.props.mainImageUploadedPicture != null) {
            form.append("file", this.props.mainImageUploadedPicture.image)
        } else {
            form.append("file", null)
        }

        axios.put(`${process.env.REACT_APP_SERVER_URL}editwallv2`,
            form,
            config).then(result => {
            if (this.state.submitPressed.toLowerCase() === "payment" ||
                (this.state.submitPressed.toLowerCase() === "editactive" && this.props.isPremium  && !this.props.isPaid)) {
                this.setState({
                    loading: false,
                    redirectToSaveDraftConfirmation: false,
                    redirectToPayment: true,
                    redirectToEditActiveConfirmation: false,
                })
            } else if (this.state.submitPressed.toLowerCase() === "draft") {
                this.setState({
                    loading: false,
                    redirectToSaveDraftConfirmation: true,
                    redirectToPayment: false,
                    redirectToEditActiveConfirmation: false,
                })
            } else {
                this.setState({
                    loading: false,
                    redirectToSaveDraftConfirmation: false,
                    redirectToPayment: false,
                    redirectToEditActiveConfirmation: true,
                })
            }
        }).catch(err => {
            let errors = {
                "Server Error": err.toString(),
            }
            this.setState({errors: errors, loading: false})
            this.openModal();
        })

    }

    submitBoardNoValidation(event) {

        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            this.setState({
                validated: true
            })
            return
        }
        this.editWall();
    }

    submitBoard(event) {

        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            this.setState({
                validated: true
            })
            return
        }

        let errors = this.additionalValidation()
        if (Object.keys(errors).length === 0) {
            this.editWall()

        } else {

            this.setState({errors: errors})
            this.openModal()
        }
    }

    setIsPremium(event){
        this.props.dispatch(updateIsPremium({
                isPremium: event.target.checked
            }
        ))
    }

    openModal() {
        this.setState({showErrorModal: true})
    }

    closeModal() {
        this.setState({showErrorModal: false})
    }

    render() {

        this.props.updateCurrentBreadcrumb("ScheduleDelivery")

        const {
            redirectToPayment,
            redirectToSaveDraftConfirmation,
            redirectToEditActiveConfirmation
        } = this.state

        if (redirectToPayment) {
            if (!this.props.isPremium) {
                return (
                    <Redirect to={'/editwall/payment/' + this.state.uuid + '/freewall'}/>
                )
            } else {
                return <Redirect to={'/editwall/payment/' + this.state.uuid + '/paidwall'}/>
            }
        }

        if (redirectToSaveDraftConfirmation) {
            return (
                <Redirect to={'/editwall/confirmation/' + this.state.uuid + '/draft'}/>
            )
        }

        if (redirectToEditActiveConfirmation) {
            return (
                <Redirect to={'/editwall/confirmation/' + this.state.uuid + '/editactive'}/>
            )
        }

        if (this.props.token !== undefined &&
            this.props.user !== undefined &&
            this.props.uuid === null
        ) {
            this.fetchWall(this.state.uuid)
        }

        if (this.props.uuid === null) {

            return (<div>
                <br></br>
                <Row>
                    <h6>Loading</h6>
                </Row>
            </div>)

        } else {

            let errors = this.state.errors
            let errorsJsx = Object.keys(errors).map(function (key, index) {
                return (<div><h6>{key}</h6><p>{errors[key]}</p></div>)
            })

            return (
                <div>
                    {this.state.loading && <div style={{textAlign: "center", marginTop: "50px"}}>

                        <ScaleLoader style={{display: "inline-block"}} color={"#8639F1"} loading={this.state.loading}
                                     size={150}/>

                    </div>}
                    {!this.state.loading && <div>
                        <Modal show={this.state.showErrorModal} onHide={this.closeModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Errors occurred <FontAwesomeIcon
                                    icon={faExclamationTriangle}/></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {errorsJsx}
                            </Modal.Body>
                        </Modal>
                        <br></br>

                        {!this.props.isSent &&
                        <Form onSubmit={this.submitBoard} validated={this.state.validated} noValidate>
                            <Row>
                                <FormGroup as={Col} sm="4" controlId="validationCustom01">
                                    <Form.Label>Modify delivery date for your wall</Form.Label>
                                    <Form.Control type="date"
                                                  className="DeliveryEdit"
                                                  required
                                                  min={new Date().toISOString().split("T")[0]}
                                                  max={new Date(new Date().getFullYear() + 2, 0, 1).toISOString().split("T")[0]}
                                                  onChange={this.handleDeliveryDateChange}
                                                  value={this.props.deliveryDate}/>
                                    <Form.Control.Feedback type="invalid">
                                        Enter a valid date (either today or in the future).
                                    </Form.Control.Feedback>
                                </FormGroup>
                            </Row>
                            <br></br>
                            <Row>
                                <FormGroup as={Col} sm="4" controlId="validationCustom02">
                                    <Form.Label>Edit delivery time (use the GMT timezone)</Form.Label>
                                    <TimePicker start="00:00"
                                                end="23:59"
                                                step={5}
                                                onChange={this.handleDeliveryTimeChange}
                                                value={this.props.deliveryTime}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Time cannot be empty
                                    </Form.Control.Feedback>
                                    <br></br>
                                </FormGroup>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Form.Label>Recipient email addresses</Form.Label>
                                    <ReactMultiEmail
                                        className="DeliveryEdit"
                                        validateEmail={email => {
                                            return isEmail(email); // return boolean
                                        }}
                                        onChange={this.handleRecipientListChange}
                                        emails={this.props.recipientList}
                                        getLabel={(
                                            email,
                                            index,
                                            removeEmail
                                        ) => {
                                            return (
                                                <div data-tag key={index}>
                                                    {email}
                                                    <span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                                </div>
                                            );
                                        }}/>
                                </Col>
                            </Row>
                            {!this.props.isPaid &&
                            <div>
                                <br></br>
                                <Row>
                                    <Col sm={4}>
                                        <Form>
                                            <Form.Check
                                                className={"premiumToggle"}
                                                type="switch"
                                                checked={this.props.isPremium}
                                                id="premium-switch"
                                                label="Upgrade to ad free Wall for £1.59 (🇺🇸 $2 approx.)?"
                                                onChange={(e) => this.setIsPremium(e)}
                                            />
                                        </Form>
                                    </Col>
                                </Row>
                            </div>}
                            {this.props.isDraft &&
                            <div>
                                <Row>
                                    <Col sm={3}>
                                        <br></br>
                                        <Button
                                            type="submit"
                                            onClick={() => this.setState({submitPressed: "draft"})}
                                            style={{
                                                float: "right",
                                                backgroundColor: " #8639F1",
                                                borderColor: " #8639F1",
                                                width: "100%",
                                                boxShadow: "0px 2px 5px -1px #333",
                                            }}>
                                            Update Draft <FontAwesomeIcon icon={faArrowRight}/>
                                        </Button>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>
                                        <br></br>
                                        <div style={{textAlign: "center"}}> Or</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>
                                        <br></br>
                                        <Button
                                            type="submit"
                                            onClick={() => this.setState({submitPressed: "payment"})}
                                            style={{
                                                float: "right",
                                                backgroundColor: " #8639F1",
                                                borderColor: " #8639F1",
                                                width: "100%",
                                                boxShadow: "0px 2px 5px -1px #333",
                                            }}>
                                            Update Draft and Activate Wall <FontAwesomeIcon icon={faArrowRight}/>
                                        </Button>
                                    </Col>
                                </Row>
                            </div>}
                            {!this.props.isDraft &&
                            <div>
                                <Row>
                                    <Col sm={3}>
                                        <br></br>
                                        <Button
                                            type="submit"
                                            onClick={() => this.setState({submitPressed: "editactive"})}
                                            style={{
                                                float: "right",
                                                backgroundColor: " #8639F1",
                                                borderColor: " #8639F1",
                                                width: "100%",
                                                boxShadow: "0px 2px 5px -1px #333",
                                            }}>
                                            Update Active Wall <FontAwesomeIcon icon={faArrowRight}/>
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                            }
                        </Form>
                        }
                        {
                            this.props.isSent &&
                            <div>
                                <Row>
                                    <Col sm={6}>
                                        <h6>Wall has already been sent, proceed to update wall.</h6>
                                    </Col>
                                </Row>
                                {!this.props.isPaid &&
                                <div>
                                    <br></br>
                                    <Row>
                                        <Col sm={4}>
                                            <Form>
                                                <Form.Check
                                                    className={"premiumToggle"}
                                                    type="switch"
                                                    checked={this.props.isPremium}
                                                    id="premium-switch"
                                                    label="Upgrade to ad free Wall for £1.59 (🇺🇸 $2 approx.)?"
                                                    onChange={(e) => this.setIsPremium(e)}
                                                />
                                            </Form>
                                        </Col>
                                    </Row>
                                </div>}
                                <Row>
                                    <Col sm={3}>
                                        <br></br>
                                        <Form onSubmit={this.submitBoardNoValidation}>
                                            <Button
                                                type="submit"
                                                onClick={() => this.setState({submitPressed: "editactive"})}
                                                style={{
                                                    float: "right",
                                                    backgroundColor: " #8639F1",
                                                    borderColor: " #8639F1",
                                                    width: "100%",
                                                    boxShadow: "0px 2px 5px -1px #333",
                                                }}>
                                                Update Wall <FontAwesomeIcon icon={faArrowRight}/>
                                            </Button>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </div>}
                </div>
            )
        }
    }
}

function mapStateToProps(state) {

    return {
        token: state.getIn(["centralState", "accessToken"]),
        user: state.getIn(["centralState", "user"]),
        uuid: state.getIn(["centralState", "editInProgress", "uuid"]),
    }
}

export default withRouter(withAuth0(connect(mapStateToProps)(EditDeliveryComponent)));