import React, {Component} from 'react';
import {Container, Col, Row, InputGroup, FormControl, Accordion, Card, Button, Form} from "react-bootstrap";
import Select from "react-select";
import placeholderSquare from "../images/PlaceholderSquare.png"
import {
    updateMainImageSearchResults,
    updateMainImageSelection, updateMainImagePicture, updateTagSelectionMainImages
} from "../actions";
import {
    updateEditWallSearchResults,
    updateEditTagSelectionMainImages,
    updateEditMainImageSelection, updateEditMainImageSearchResults, updateEditingMainImagePicture,
} from "../actions/edit"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import ImageUploader from "react-images-upload";
import {GiphyFetch} from "@giphy/js-fetch-api";
import {Grid} from "@giphy/react-components";


const gf = new GiphyFetch('SlBsyziD4SSO7RrUbiOdBDvbYNLR70u1')

class MainImageUploadModalComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sendingSearchRequest: false
        }

        this.onPictureUpload = this.onPictureUpload.bind(this);
    }

    onPictureUpload(picture) {
        let loadedPicture = picture[0]
        let imgTemporaryUrl = URL.createObjectURL(loadedPicture)
        if (loadedPicture !== undefined) {

            if (this.props.isCreate === true) {
                this.props.dispatch(updateMainImagePicture({
                    uploadedPicture: {
                        image: loadedPicture,
                        imgTemporaryUrl: imgTemporaryUrl
                    }
                }))
            } else {
                this.props.dispatch(updateEditingMainImagePicture({
                    uploadedPicture: {
                        image: loadedPicture,
                        imgTemporaryUrl: imgTemporaryUrl
                    }
                }))
            }

            this.props.handleMainImageUploadChange(false)
        }
    }


    render() {

        return (
            <div>
                <Container fluid>
                    <Row>
                        <Col sm={6}>
                            <br></br>
                            <h1>Upload Main Images</h1>
                            <br/>
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col sm={12}>
                            <ImageUploader
                                withLabel={true}
                                label={"Upload JPG and PNG filetypes only, up to 5Mb in size."}
                                singleImage={true}
                                withIcon={true}
                                buttonText='Choose images'
                                onChange={this.onPictureUpload}
                                imgExtension={['.jpg', '.png', '.jpeg']}
                                maxFileSize={5242880}
                            />
                        </Col>
                    </Row>
                    <br></br>


                </Container>
            </div>)
    }
}

export default MainImageUploadModalComponent;